import { createTheme } from '@mui/material/styles';

import ManulifeSansBoldEOT from './webfonts/ManulifeJHSans-Optimized-Bold.eot';
import ManulifeSansBoldTTF from './webfonts/ManulifeJHSans-Optimized-Bold.ttf';
import ManulifeSansBoldWOFF from './webfonts/ManulifeJHSans-Optimized-Bold.woff';
import ManulifeSansBoldWOFF2 from './webfonts/ManulifeJHSans-Optimized-Bold.woff2';

import ManulifeSansDemiboldEOT from './webfonts/ManulifeJHSans-Optimized-Demibold.eot';
import ManulifeSansDemiboldTTF from './webfonts/ManulifeJHSans-Optimized-Demibold.ttf';
import ManulifeSansDemiboldWOFF from './webfonts/ManulifeJHSans-Optimized-Demibold.woff';
import ManulifeSansDemiboldWOFF2 from './webfonts/ManulifeJHSans-Optimized-Demibold.woff2';

import ManulifeSansLightEOT from './webfonts/ManulifeJHSans-Optimized-Light.eot';
import ManulifeSansLightTTF from './webfonts/ManulifeJHSans-Optimized-Light.ttf';
import ManulifeSansLightWOFF from './webfonts/ManulifeJHSans-Optimized-Light.woff';
import ManulifeSansLightWOFF2 from './webfonts/ManulifeJHSans-Optimized-Light.woff2';

import ManulifeSansRegularEOT from './webfonts/ManulifeJHSans-Optimized.eot';
import ManulifeSansRegularTTF from './webfonts/ManulifeJHSans-Optimized.ttf';
import ManulifeSansRegularWOFF from './webfonts/ManulifeJHSans-Optimized.woff';
import ManulifeSansRegularWOFF2 from './webfonts/ManulifeJHSans-Optimized.woff2';

import ManulifeSerifItalicEOT from './webfonts/ManulifeJHSerif-Optimized-Italic.eot';
import ManulifeSerifItalicTTF from './webfonts/ManulifeJHSerif-Optimized-Italic.ttf';
import ManulifeSerifItalicWOFF from './webfonts/ManulifeJHSerif-Optimized-Italic.woff';
import ManulifeSerifItalicWOFF2 from './webfonts/ManulifeJHSerif-Optimized-Italic.woff2';

import { ReactComponent as ChevronDownIcon } from './images/icon-chevron-down.svg';
import AccordionExpandIcon from './components/AccordionExpandIcon';
import { ReactComponent as RadioUncheckedIcon} from './images/icon-radio-unchecked.svg';
import { ReactComponent as RadioCheckedIcon} from './images/icon-radio-checked.svg';

const lineHeightHelper = (lineHeightPx, fontSizePx) => (Math.round((lineHeightPx / fontSizePx) * 1000) / 1000);

const breakpoints = {
  values: {
    xs: 0,
    sm: 375,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1920
  }
};

const palette = {
  primary: {
    main: '#C14A36', // coral
    dark: '#DB1F00'
  },
  secondary: {
    main: '#00A758', // green
    light: '#00C46E',
    dark: '#008048',
    lighter: '#ACE5C4'
  },
  navy: {
    main: '#282B3E',
    light: "#424559",
    light2: '#34384B',
    lighter: '#5E6073',
    darker: '#202232'
  },
  gray: {
    light: '#fafafa',
    input: '#B9B9B9',
    optionBackground: '#EDEDED',
    disabledButton: '#5E6073'
  },
  white: '#FFFFFF'
};

const themeHelper = createTheme({
  breakpoints: breakpoints
});

// A custom theme for this app
const theme = createTheme({
  breakpoints: breakpoints,
  palette: palette,

  typography: {
    fontFamily: 'Manulife Sans',

    body1: {
      fontSize: 16,
      color: palette.navy.main
    },

    // Billboard h1
    h1: {
      fontSize: 33,
      lineHeight: lineHeightHelper(36, 33),
      fontWeight: 400,
      [themeHelper.breakpoints.up('lg')]: {
        fontSize: 46,
        lineHeight: lineHeightHelper(46, 46),
      }
    },

    modalHeader: {
      fontSize: 40,
      lineHeight: lineHeightHelper(46, 40),
      fontWeight: 400
    }
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Manulife Sans';
          src: url(${ManulifeSansDemiboldEOT});
          src: url(${ManulifeSansDemiboldEOT}?#iefix) format('embedded-opentype'),
            url(${ManulifeSansDemiboldWOFF2}) format('woff2'),
            url(${ManulifeSansDemiboldWOFF}) format('woff'),
            url(${ManulifeSansDemiboldTTF}) format('truetype');
          font-weight: 600;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Manulife Sans';
          src: url(${ManulifeSansBoldEOT});
          src: url(${ManulifeSansBoldEOT}?#iefix) format('embedded-opentype'),
            url(${ManulifeSansBoldWOFF2}) format('woff2'),
            url(${ManulifeSansBoldWOFF}) format('woff'),
            url(${ManulifeSansBoldTTF}) format('truetype');
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Manulife Sans';
          src: url(${ManulifeSansRegularEOT});
          src: url(${ManulifeSansRegularEOT}?#iefix) format('embedded-opentype'),
            url(${ManulifeSansRegularWOFF2}) format('woff2'),
            url(${ManulifeSansRegularWOFF}) format('woff'),
            url(${ManulifeSansRegularTTF}) format('truetype');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Manulife Sans';
          src: url(${ManulifeSansLightEOT});
          src: url(${ManulifeSansLightEOT}?#iefix) format('embedded-opentype'),
            url(${ManulifeSansLightWOFF2}) format('woff2'),
            url(${ManulifeSansLightWOFF}) format('woff'),
            url(${ManulifeSansLightTTF}) format('truetype');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Manulife Serif';
          src: url(${ManulifeSerifItalicEOT});
          src: url(${ManulifeSerifItalicEOT}?#iefix) format('embedded-opentype'),
            url(${ManulifeSerifItalicWOFF2}) format('woff2'),
            url(${ManulifeSerifItalicWOFF}) format('woff'),
            url(${ManulifeSerifItalicTTF}) format('truetype');
          font-weight: 400;
          font-style: italic;
          font-display: swap;
        }

        html, body, #root {
          height: 100%;
          scroll-behavior: smooth;
        }
      `,
    },

    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          maxWidth: (990 + 24*2),
          [themeHelper.breakpoints.up('lg')]: {
            maxWidth: (990 + 24*2)
          }
        },
      }
    },

    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ChevronDownIcon/>
      },
      styleOverrides: {
        inputRoot: {
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 13,
        },
        input: {
          padding: 0
        },
        clearIndicator: {
          padding: 2,
        },
        popupIndicator: {
          padding: 2
        },
        option: {
          fontSize: 16,
          fontWeight: 600,
        },
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 16,
          backgroundColor: palette.white
        },
        input: {
          padding: `${themeHelper.typography.pxToRem(12)} ${themeHelper.typography.pxToRem(12)}`,
        },
      }
    },

    MuiOutlinedInput: {
      defaultProps: {
        color: 'navy'
      },
      styleOverrides: {
        notchedOutline: {
          borderRadius: 0,
          borderColor: palette.navy.light,
          borderWidth: 1
        },
        input: {
          padding: `${themeHelper.typography.pxToRem(12)} ${themeHelper.typography.pxToRem(12)}`,
          "::placeholder": {
            color: palette.navy.light2,
            opacity: 1,
            fontWeight: 300
          }
        }
      }
    },

    MuiFormLabel: {
      defaultProps: {
        color: 'navy'
      },
      styleOverrides: {
        root: {
          color: palette.navy.light2,
          fontSize: 16,
          lineHeight: 1.5,
          fontWeight: 600,
        }
      }
    },

    MuiRadio: {
      defaultProps: {
        icon: <RadioUncheckedIcon/>,
        checkedIcon: <RadioCheckedIcon/>
      },
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: 20,
        }
      }
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          fontSize: 16,
          fontFamily: 'inherit',
          ":focus-visible": {
            outline: '-webkit-focus-ring-color auto 1px',
            outlineOffset: '1px'
          }
        },
      }
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true
      },
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: 1,
          fontWeight: 700,
          textTransform: 'none',
          borderRadius: 0,
          padding: 16,
          minWidth: 200
        },
        outlined: {
          paddingTop: 13,
          paddingBottom: 13
        },
        containedPrimary: {
          ":disabled": {
            background: palette.gray.optionBackground,
            color: palette.gray.disabledButton
          },
          ":focus-visible": {
            background: palette.primary.dark
          }
        },
        outlinedPrimary: {
          borderColor: palette.primary.main,
          background: palette.white,
          borderWidth: 2,
          color: palette.primary.main,
          ":hover": {
            borderColor: palette.primary.dark,
            background: palette.primary.dark,
            borderWidth: 2,
            color: palette.white,
          },
          ":disabled": {
            background: palette.white,
            borderWidth: 2,
            color: palette.gray.disabledButton
          },
          ":focus-visible": {
            borderColor: palette.primary.dark,
            background: palette.primary.dark,
            borderWidth: 2,
            color: palette.white,
          }
        },
      }
    },

    MuiAccordion: {
      defaultProps: {
        disableGutters: true, // to prevent the accordion growing on expand. If space between accordions is needed, please override it only there or apply custom classes
        square: true
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: `1px solid ${palette.gray.optionBackground}`,
          "::before": {
            display: "none",
          },
          "&.Mui-expanded": {
            borderColor: palette.navy.lighter
          },
          ".MuiAccordionDetails-root": {
            padding: `0 ${themeHelper.typography.pxToRem(15+20+15)} ${themeHelper.typography.pxToRem(23)} ${themeHelper.typography.pxToRem(15+20+15)}`,
            [themeHelper.breakpoints.up('lg')]: {
              padding: `0 ${themeHelper.typography.pxToRem(20+20+20)} ${themeHelper.typography.pxToRem(23)} ${themeHelper.typography.pxToRem(20+20+20)}`
            }
          }
        },
      }
    },

    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <AccordionExpandIcon/>,
      },
      styleOverrides: {
        root: {
          padding: `${themeHelper.typography.pxToRem(23)} ${themeHelper.typography.pxToRem(15)}`,
          [themeHelper.breakpoints.up('lg')]: {
            padding: `${themeHelper.typography.pxToRem(23)} ${themeHelper.typography.pxToRem(20)}`
          }
        },
        expandIconWrapper: {
          order: -1,
          marginRight: 15,
          [themeHelper.breakpoints.up('lg')]: {
            marginRight: 20,
          },
          "&.Mui-expanded": {
            transform: 'none'
          }
        },
        content: {
          margin: 0,
          fontSize: 16,
          [themeHelper.breakpoints.up('lg')]: {
            fontSize: 20,
          }
        },
      },
    },
  }
});

export default theme;