import { useEffect } from 'react';
import { Button, ButtonBase, TextField, Dialog, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as ActiveIcon } from '../../images/icon-active.svg';
import { ReactComponent as WhiteCloseIcon } from '../../images/icon-close-white.svg';
import { ReactComponent as NavyCloseIcon } from '../../images/icon-close-navy.svg';
import styles from './CalculatorSaveQuoteModal.module.scss';

export default function CalculatorSaveQuoteModal({open, handleClose, isSuccess, emailInputValue, emailInputIsValid, onEmailInputChange, onEmailInputKeyUp, onSubmitButtonClick}) {
    const { t, i18n } = useTranslation();
    const rootElement = document.getElementById('root');

     // Remove aria-hidden=true from root element that is flagged by accessibility checkers
     useEffect(() => {
        if (rootElement.hasAttribute('aria-hidden')) {
            rootElement.removeAttribute('aria-hidden');
        }
    }, [open]);

    return (
        <Dialog open={open} PaperProps={{'aria-modal': 'true'}} onClose={handleClose} className={styles['calculator-quiz-modal'] + ' ' + (isSuccess ? styles['success'] : '')}>
            <ButtonBase onClick={handleClose} className={styles['calculator-quiz-modal-close']}>
                <Typography style={visuallyHidden}>{t('calculatorSaveQuoteModal.close')}</Typography>
                { !isSuccess && <WhiteCloseIcon className={styles['calculator-quiz-modal-close-icon']}/>}
                { isSuccess && <NavyCloseIcon className={styles['calculator-quiz-modal-close-icon']}/>}
            </ButtonBase>

            {!isSuccess &&
            <div className={styles['calculator-quiz-modal-form']}>
                <div className={styles['calculator-quiz-modal-form-header']}>
                    <Trans i18nKey="calculatorSaveQuoteModal.header"/>
                </div>
                <Typography style={visuallyHidden} id="save-quote-modal-email"><Trans i18nkey="calculatorSaveQuoteModal.emailLabel"/></Typography>
                <TextField
                    onChange={onEmailInputChange}
                    onKeyUp={onEmailInputKeyUp}
                    value={emailInputValue}
                    InputLabelProps={{shrink: false, className: styles['calculator-quiz-modal-form-input-label']}}
                    error={emailInputValue === '' ? false : !emailInputIsValid}
                    helperText={emailInputValue === '' ? '' : (emailInputIsValid ? '' : t(`calculatorSaveQuoteModal.emailError`))}
                    aria-labelledby="save-quote-modal-email"
                    name="name"
                    label={<Trans i18nKey="calculatorSaveQuoteModal.emailLabel"/>}
                    className={styles['calculator-quiz-modal-form-input'] + ' ' + (emailInputValue === '' ? '' : 'has-value')}/>

                <div className={styles['calculator-quiz-modal-form-footer']}>
                    <Button disabled={!emailInputIsValid} onClick={onSubmitButtonClick} className={styles['calculator-quiz-modal-form-footer-submit']} variant="contained"><Trans i18nKey="calculatorSaveQuoteModal.submitLabel"/></Button>
                    <div className={styles['calculator-quiz-modal-form-footer-disclaimer']}>
                        <Trans i18nKey="calculatorSaveQuoteModal.disclaimer"/>
                    </div>
                </div>
            </div>}

            {isSuccess &&
            <div className={styles['calculator-quiz-modal-success']}>
                <div className={styles['calculator-quiz-modal-success-header']}>
                    <span className={styles['calculator-quiz-modal-success-header-text']}>
                        <Trans i18nKey="calculatorSaveQuoteModal.success.header"/>
                    </span>
                    <ActiveIcon className={styles['calculator-quiz-modal-success-header-icon']}/>
                </div>
                <div className={styles['calculator-quiz-modal-success-copy1']}>
                    <Trans i18nKey="calculatorSaveQuoteModal.success.copy1"/>
                </div>
                <div className={styles['calculator-quiz-modal-success-copy2']}>
                    <Trans i18nKey="calculatorSaveQuoteModal.success.copy2"/>
                </div>
            </div>}
        </Dialog>
    );
}